@font-face {
  font-family: 'SFProText';
  src: url('../../../../assets/fonts/SFProText-Bold.otf') format('opentype');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText';
  src: url('../../../../assets/fonts/SFProText-BoldItalic.otf') format('opentype');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'SFProText';
  src: url('../../../../assets/fonts/SFProText-Regular.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: 'SFProText';
  src: url('../../../../assets/fonts/SFProText-RegularItalic.otf') format('opentype');
  font-weight: normal;
  font-style: italic;
}